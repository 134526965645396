import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import media from '@oneflare/flarekit/lib/libs/media';
import Image from 'next/image';
import { styled, css } from 'styled-components';

const ofHomeStyle = css`
  bottom: 45px;
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
`;

export const BannerStyled = styled.section<{
  $isOneflareHomePage?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: ${({ theme: { zIndex } }) => zIndex[1]};
  margin-top: 0;
  padding-block: 29px;
  padding-inline: 1.25rem;
  text-align: center;

  ${({ $isOneflareHomePage }) => $isOneflareHomePage && ofHomeStyle}

  ${media.lgUp`
    padding-block: 0;
    height: 87px;
    margin-top: -65px;
    z-index: ${({ theme: { zIndex } }) => zIndex[1]};
  `}
`;

export const BannerImageStyled = styled(Image)`
  z-index: -1;
  object-fit: cover;
`;

export const ParagraphStyled = styled(Paragraph)`
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.color.white};
  display: unset;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 0;

  .span-banner {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: self-start;

    span {
      display: flex;
      margin-inline: 5px;
    }
  }

  .span-visit {
    display: block;

    ${media.lgUp`
      display: unset;
    `}
  }

  .hide-mobile {
    display: none;

    ${media.lgUp`
      display: unset;
    `}
  }

  ${media.lgUp`
    display: flex;
  `}
`;

export const AnchorStyled = styled(Anchor)`
  margin-bottom: 0;
  margin-inline: 5px;
  color: ${({ theme }) => theme.color.white};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.color.white};
  }
`;
