import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { memo, useMemo, useState } from 'react';

import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { GET_COST_GUIDES_FOR_HOMEPAGE } from 'queries/shared/costGuides';
import { slugHelper } from 'shared/utils/helpers';

import {
  CostGuideContainerStyled,
  CostGuideWrapperStyled,
  CostGuideImageStyled,
  CostGuideHeaderStyled,
  CostGuideReviewStyled,
  ButtonStyled,
  LinkedListStyled,
  HeaderStyled,
  CostGuideAnchorStyled,
  SummaryStyled,
  CircleMoneyStyled,
  HeaderOtherStyled,
  CostGuidesOtherWrapperStyled,
  AvgPriceStyled,
  ButtonLinkStyled
} from './styled/CostGuides';

const maxTiles = 8;

interface ICostGuidesHome {
  className?: string;
  heading?: string;
  isBot?: boolean;
  showCostsHomeButton?: boolean;
  showSeeMoreButton?: boolean;
  subHeading?: string;
}

const CostGuidesHome = ({
  heading,
  showSeeMoreButton,
  subHeading,
  className,
  showCostsHomeButton,
  isBot
}: ICostGuidesHome) => {
  const [showOtherCostGuides, setShowOtherCostGuides] = useState(false);

  const handleHideOtherCostGuides = () => {
    setShowOtherCostGuides((prevToggle) => !prevToggle);
  };

  const { data } = useQuery(GET_COST_GUIDES_FOR_HOMEPAGE, {
    fetchPolicy: 'cache-first',
    onError: (exception) => {
     DataDogRumAgent.addRumError(exception, 'Oneflare | Home | Cost Guides Query');
    }
  });

  const primaryCostGuides = useMemo(() => (
    data && !isEmpty(data.getCostGuidesForHomepage)
      ? data.getCostGuidesForHomepage?.slice(0, maxTiles)
      : []
  ), [data]);

  const remainingCostGuides = useMemo(() => (
    data && !isEmpty(data.getCostGuidesForHomepage)
      ? data.getCostGuidesForHomepage?.slice(maxTiles, data.getCostGuidesForHomepage?.length)
      : []
  ), [data]);

  return (
    <CostGuideContainerStyled className={className}>
      <HeaderStyled>{heading}</HeaderStyled>
      <SummaryStyled>
        {subHeading}
      </SummaryStyled>
      <CostGuideWrapperStyled>
        {primaryCostGuides.map(({ title, image, averagePrice }) => {
          return (
            <CostGuideAnchorStyled
              href={`/costs/${slugHelper(title)}`}
              key={title}
            >
              <CostGuideImageStyled
                loading="lazy"
                alt={image?.alt}
                src={image?.medium}
                width="100%"
                height="100%"
              />
              <CostGuideHeaderStyled>
                {`${title} costs`}
              </CostGuideHeaderStyled>
              <CircleMoneyStyled
                kind="solid"
                bgColor={({ theme }) => theme.color.primary}
                fill={({ theme }) => theme.color.white}
              />
              <CostGuideReviewStyled>
                {'Average price '}
                <AvgPriceStyled>{averagePrice}</AvgPriceStyled>
              </CostGuideReviewStyled>
            </CostGuideAnchorStyled>
          );
        })}
      </CostGuideWrapperStyled>
      {(isBot || showOtherCostGuides) && (
        <>
          <HeaderOtherStyled>Other cost guides</HeaderOtherStyled>
          <CostGuidesOtherWrapperStyled>
            {remainingCostGuides.map(({ title }) => {
              return (
                <LinkedListStyled
                  key={title}
                  href={`/costs/${slugHelper(title)}`}
                  linksOnly
                >
                  {`${title} costs`}
                </LinkedListStyled>
              );
            })}
          </CostGuidesOtherWrapperStyled>
        </>
      )}
      {showSeeMoreButton && remainingCostGuides.length > 0 && (
        <ButtonStyled
          kind="default-sm"
          label={`See ${showOtherCostGuides ? 'less' : 'more'} cost guides`}
          onClick={handleHideOtherCostGuides}
        />
      )}
      {showCostsHomeButton && (
        <ButtonLinkStyled link="/costs" kind="default-sm">
          See more cost guides
        </ButtonLinkStyled>
      )}
    </CostGuideContainerStyled>
  );
};

CostGuidesHome.defaultProps = {
  className: '',
  heading: '',
  isBot: false,
  showSeeMoreButton: false,
  showCostsHomeButton: false,
  subHeading: ''
};

export default memo(CostGuidesHome);
