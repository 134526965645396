import Airtasker from '@oneflare/flarekit/lib/components/logos/Airtasker/Airtasker';
import { memo } from 'react';

import {
  BannerStyled,
  BannerImageStyled,
  ParagraphStyled,
  AnchorStyled
} from './styled/BannerStyled';

type BannerProps = {
  isOneflareHomePage?: boolean;
  url: string;
  group: string;
};

const Banner = ({
  isOneflareHomePage = false,
  url,
  group
}: BannerProps) => {
  return (
    <BannerStyled $isOneflareHomePage={isOneflareHomePage}>
      {url && (
        <BannerImageStyled
          src={url}
          alt="Banner"
          fill
          priority
        />
      )}
      <ParagraphStyled>
        <span className="span-banner">
          {group}
          , an
          <Airtasker height="25px" width="90px" fillColor="#fff" />
          company.&nbsp;
        </span>
        <span className="hide-mobile">
          Now with a greater network of qualified service providers.&nbsp;
        </span>
        <span className="span-visit">
          Visit
          <AnchorStyled
            href="https://www.airtasker.com/au/"
          >
            Airtasker
          </AnchorStyled>
        </span>
      </ParagraphStyled>
    </BannerStyled>
  );
};

export default memo(Banner);
