import { gql } from '@apollo/client';

export const GET_COST_GUIDES = gql`
  query GetCategoryCostGuides($categorySlug: String!) {
    getCategoryCostGuides(categorySlug: $categorySlug) {
      title
      averagePrice
      guideUrl
      image {
        alt
        description
        thumbnail
        medium
        large
      }
    }
  }
`;

export const GET_COST_GUIDES_FOR_HOMEPAGE = gql`
  {
    getCostGuidesForHomepage {
      title
      averagePrice
      guideUrl
      image {
        alt
        description
        thumbnail
        medium
        large
      }
    }
  }
`;
