import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import Button from '@oneflare/flarekit/lib/components/Button';
import ButtonLink from '@oneflare/flarekit/lib/components/ButtonLink';
import H2 from '@oneflare/flarekit/lib/components/H2';
import H3 from '@oneflare/flarekit/lib/components/H3';
import CircleMoney from '@oneflare/flarekit/lib/components/icons/CircleMoney';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const CostGuideContainerStyled = styled.section`
  margin: 0 auto;
  padding: 54px 20px 0;
  max-width: 1120px;

  ${media.mdUp`
    padding-top: 78px;
    padding: 170px 32px 0;
  `}

  ${media.lgUp`
    padding-top: 160px;
  `}

  ${media.xlUp`
    padding: 110px 0 0;
  `}
`;

export const CostGuideAnchorStyled = styled(Anchor)`
  max-width: 340px;
  display: block;
  margin: 0;
  width: 100%;
  font-size: 16px;

  &:hover {
    text-decoration: none;
  }
`;

export const HeaderStyled = styled(H2)`
  margin: 0 0 16px;
  width: 100%;
  line-height: 1.5;
  font-family: ${({ theme }) => theme.font.secondaryBold};
  color: ${({ theme }) => theme.color.charcoal};
  font-size: 22px;

  ${media.lgUp`
    padding: 0;
    font-size: 28px;
  `}
`;

export const HeaderOtherStyled = styled(H2)`
  margin: 0 0 16px;
  width: 100%;
  line-height: 1.5;
  font-size: 18px;
  font-family: ${({ theme }) => theme.font.secondaryBold};
  color: ${({ theme }) => theme.color.charcoal};

  ${media.mdUp`
    margin: 0 0 16px;;
  `}

  ${media.lgUp`
    margin: 0 0 24px;
  `}
`;

export const CostGuideWrapperStyled = styled.div`
  display: grid;
  grid-gap: 32px 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 0 32px;

  ${media.smUp`
  grid-gap: 40px 20px;
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.mdUp`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

export const SummaryStyled = styled(Paragraph)`
  margin-bottom: 40px;
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 18px;
  line-height: 1.5;

  ${media.mdUp`
    padding: 0;
  `}
`;

export const CostGuideImageStyled = styled.img`
  height: 126px;
  width: 100%;
  max-width: 340px;
  object-fit: cover;
  border-radius: 4px;
  ${media.mdUp`
    height: 140px;
  `}

  ${media.lgUp`
    height: 180px;
  `}
`;

export const CostGuideHeaderStyled = styled(H3)`
  margin: 8px 0 4px;
  line-height: 1.5;
  width: 100%;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-weight: bold;
  color: ${({ theme }) => theme.color.charcoal};

  &:hover {
    text-decoration: underline;
  }
`;

export const CostGuideReviewStyled = styled(Paragraph)`
  position: relative;
  margin: 0 0 0 8px;
  display: inline;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.spaceGrey};
  font-size: 13px;

  ${media.mdUp`
    margin-top: 0;
  `}
`;

export const CostGuidesOtherWrapperStyled = styled.div`
  display: grid;
  grid-gap: 8px 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 0 32px;

  ${media.smUp`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.mdUp`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

export const ButtonStyled = styled(Button)`
  display: block;
  width: 100%;
  ${media.smUp`
    width: auto;
  `}
`;

export const LinkedListStyled = styled(Anchor)`
  font-size: 16px;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  padding-bottom: ${({ linksOnly }) => (linksOnly ? '0' : '38px')};
  margin-bottom: 8px;
`;

export const CircleMoneyStyled = styled(CircleMoney).attrs(({ theme }) => ({
  fillColor: theme.color.white,
  fill: theme.color.primary,
  height: 16,
  width: 16
}))`
  position: relative;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
`;

export const AvgPriceStyled = styled.span`
  font-weight: bold;
  display: block;

  ${media.mdUp`
    display: inline-block;
  `}
`;

export const ButtonLinkStyled = styled(ButtonLink)`
  margin: 0 auto;
  width: 100%;

  ${media.mdUp`
     width: auto;
  `}
`;
